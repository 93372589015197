import { Box, Button, FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import TrainerService from '../../../../services/trainer/TrainerService';
import { PROCEDURE_VISIBILITY_SCOPE } from '../../../../shared/enums';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import { Toast } from '../../../Notifications/Notification';

const PublishToIntegrationDialog = (props: any) => {
  const [integrationInstances, setIntegrationInstances] = React.useState<any[]>([]);
  const [integrationSystem, setIntegrationSystem] = React.useState<string|undefined>();

  React.useEffect(() => {
    TrainerService.getIntegrations().then((res) => {
      setIntegrationInstances(res.data?.data?.systems || []);
    });
  }, []);

  const handleIntegrationChange = (e) => {
    if (e.target.value === 'notRequired') {
      setIntegrationSystem(undefined);
    } else {
      setIntegrationSystem(e.target.value);
    }
  };

  const handleIntegrationSubmit = (e) => {
    if(integrationSystem){
      TrainerService.saveIntegration(props.procedure.procedureId, integrationSystem).then((res) => {
        Toast.success(t('VERSION_CREATE', { ns: 'success' }), "");
      }).catch(console.error);
    }
    props.closeDialog()
  }

  const { t } = useTranslation();

  return (
    <BasicDialog
      title={t("PROCEDURE.PUBLISH_TO_LEARNING_MGMT_SYSTEM")}
      onClose={() => {
        props.closeDialog();
      }}
      onOpen={props.openDialog}
      actions={
        <>
          <Button
            variant="contained"
            sx={{ borderRadius: '8px' }}
            onClick={handleIntegrationSubmit}
            disabled = {!integrationInstances.length}
          >
            {' '}
            {t('PROCEDURE.CARD_PUBLISH')}{' '}
          </Button>
        </>
      }
    >
      {props.procedure?.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.GLOBAL ? (
        <Box
          component="div"
        >
          <FormControl fullWidth>
            <FormLabel className="dialog-header">{t('BUTTON.PUBLISH_TO_LEARNING_MANAGEMENT_SYSTEM')}</FormLabel>

            <Select
              value={integrationSystem ? integrationSystem : 'notRequired'}
              onChange={handleIntegrationChange}
              inputProps={{ 'aria-label': 'Preffered language' }}
              sx={{ height: '2.5rem' }}
              fullWidth
            >
              <MenuItem
                value="notRequired"
                key="notRequired"
              >
                {t('BUTTON.NOT_REQUIRED')}
              </MenuItem>
              {integrationInstances?.map((integration) => (
                <MenuItem
                  key={integration.integrationId}
                  value={integration.integrationId}
                >
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : null}
    </BasicDialog>
  );
};

export default PublishToIntegrationDialog;
