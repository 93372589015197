export const environment = {
  production: true,
  AppConfigs: {
    env: 'uat',
    appid: 'Mentor',
    rbacUrl: 'https://mentor-uat2.cdsvisual.net/api/rbac',
    BackendURL: 'https://mentor-uat2.cdsvisual.net/api/mentor',
    studioURL: 'https://mentor-uat2.cdsvisual.net/workinstructionstudioreact/?',
    tenant: 'uatcdsmentor.onmicrosoft.com',
    clientId: 'f6f66c68-4a83-4f69-851c-4b8b2f73d040',
    tenantAuthority: 'uatcdsmentor.b2clogin.com',
    b2cScopes: 'https://uatcdsmentor.onmicrosoft.com/f6f66c68-4a83-4f69-851c-4b8b2f73d040/user.read',
    redirectUrl: 'https://mentor-uat2.cdsvisual.net',
    fileManagerApi: 'https://vfm-uat2.cdsvisual.net',
    b2cPolicy: 'B2C_1A_SIGNUP-SIGNIN',
    userFormat: 'both',
    solutionName: 'Platform',
    b2cLoginURL: 'https://uatcdsmentor.b2clogin.com',
    fileUploadLimit: {
      customPpe: 5,
      cad: 250,
      media: 2000,
      procedureReferenceDoc: 300,
      companyLogo: 5
    },
    thumbnailLimit: {
      fileLimitThumbnail: 10,
      fileTypeThumbnail: 'PNG, JPEG'
    },
    commentsMediaLimit: {
      fileLimitComment: 500,
      fileTypeComment: '“.png”, “.jpeg”, “.pdf”, “.mp4”, “.mov”',
    },
    amplitudePublicKey: 'fea74b7dc0c468fd6eb2a8bb6b059d12',
    chargebeeSiteName: 'cdsvisualmentor-test',
    googleAnalyticsTag: 'G-940V9Y57RV',
    regexForEmail: '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/',
    doverADIDP: '60d270cbe61b42109d87f997cbe528dc',
    internalUsers: ['dovercorp.com'],
    clientDataEncryptionHash: 'cVBPNGVybTMxNkZOWEF6bHpJMDBVWVFGNzMyY0hFRmc=',
    internalLinkIdentifier: 'https://vfm-uat2-intrnl.cdsvisual.net:444',
    govServer: false, 
    cdsInternalApiKey: 'Haq80CwQLi382pk76pbUUargJeVI8TqQ4IByY798'
  },
};
